export const GeneralStepsEnum = {
    Landing: 0,
    LicenseChecker: 1,
    TypeSelect: 2,
    BrandModel: 3,
    DamageCause: 4,
    GlassDamage: 5,
    Lease: 6,
    Insurance: 7,
    Appointment: 8,
    Form: 9,
    EnableResume: 10,
    Finished: 11,
}
