import {ApiEnum} from "../../constants/ApiEnum";
import axios from "axios";
import {disableApiError} from "../../../app/rootReducer";

export const ApiDossierDelete = async (dossierUuid: string, dispatch: any) => {

    return await axios.delete(ApiEnum.DOSSIER + '/delete', {
        data: {
            uuid: dossierUuid,
        }
    }).then(function (response) {
        dispatch(disableApiError())
        return response
    }).catch(function (error) {
        return error.response
    })

}
