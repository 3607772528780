import {setInsuranceActiveContractor,} from "../vehicleInformationSlice";
import {useGetContractorsByTypeQuery} from "../../apiData/apiDataSlice";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {useEffect, useRef} from "react";
import {setDossierLoading, updateDossier} from "../../../app/rootReducer";
import ContractorInterface from "../../../common/interfaces/ContractorInterface";
import FormSelectString from "../../../common/components/form/FormSelectString";
import {PatchStepsEnum} from "../../../common/constants/PatchStepsEnum";
import {setGeneralStep} from "../../helpType/helpTypeSlice";
import {GeneralStepsEnum} from "../../../common/constants/GeneralStepsEnum";
import {unsetAppointment} from "../../appointment/appointmentSlice";

const VehicleInformationInsurance = () => {
    const dispatch = useAppDispatch()

    const {activeHelpType} = useAppSelector((state) => state.helpType)
    const {insurance, fundingType} = useAppSelector((state) => state.vehicleInformation)
    const {dossier} = useAppSelector((state) => state.root)
    const {data: contractors, isLoading} = useGetContractorsByTypeQuery({
        type: 'insurance',
        dossierId: dossier.dossier_id,
        activeHelpType: activeHelpType
    });

    useEffect(() => {
        if (isLoading) {
            dispatch(setDossierLoading(true))
        } else {
            dispatch(setDossierLoading(false))
        }
    }, [isLoading]);

    const renderCondition = dossier && dossier.car_brand && dossier.car_model
    const scrollRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        scrollRef.current?.scrollIntoView({block: "start", inline: "nearest", behavior: 'smooth'})
    }, [fundingType, insurance.activeContractorId])

    const handleContractorClick = (activeContractorId: number) => {
        dispatch(setGeneralStep(GeneralStepsEnum.Insurance))
        dispatch(unsetAppointment())

        if (typeof activeContractorId !== 'undefined' && activeContractorId) {

            dispatch(updateDossier({
                data: {contractor_id: activeContractorId},
                method: PatchStepsEnum.PatchContractor
            })).then(() => {
                dispatch(setGeneralStep(GeneralStepsEnum.Appointment))
            })
        }
    }

    if (renderCondition) {
        return (
            <>
                <div className="s-appointment__group border-color-primary">
                    <h1 className="s-appointment__group__title">Wat is je verzekeringsmaatschappij?</h1>
                    {contractors &&
                        <div className="c-form__select react-select">
                            <FormSelectString
                                isClearable={true}
                                value={insurance.activeContractorId}
                                options={contractors.map((insurance: ContractorInterface) => {
                                    return {
                                        value: insurance.id,
                                        label: insurance.name
                                    }
                                })}
                                onChange={(id: string) => {
                                    dispatch(setInsuranceActiveContractor(parseInt(id)))
                                    handleContractorClick(parseInt(id))
                                }}
                            />
                            <p style={{'marginTop': '1rem'}}>Meld de schade ook direct bij de verzekeringsmaatschappij,
                                zo kunnen we je sneller van dienst zijn</p>
                        </div>
                    }
                </div>


                {!insurance.activeContractorId &&
                    <>
                        <div className="s-appointment__group border-color-primary">
                            <p>Staat je verzekeringsmaatschappij er niet tussen? Wij helpen je graag telefonisch verder.
                                Neem contact op met onze servicedesk 0900 - 66 11 11 1</p>
                        </div>
                    </>
                }
            </>
        )
    } else return null
}

export default VehicleInformationInsurance

