import {useAppSelector} from "../../../app/hooks";
import {format} from "date-fns";
import {nl} from "date-fns/locale";
import {scrollToId} from "../../../common/helpers/scrollToId";

const OverviewAppointment = () => {

    const {dossier} = useAppSelector((state) => state.root)

    if (dossier && dossier.appointment) {

        return (

                <div className="s-appointment__group border-color-primary">
                    <div className="s-appointment__group__header">
                        <h1 className="s-appointment__group__title">Gekozen vestiging</h1>
                        <button className="btn--info" onClick={() => {
                            scrollToId('establishment')
                        }}>
                            <span><u>Wijzig</u></span>
                        </button>
                    </div>

                    {dossier.appointment.establishment &&
                    <h1 className="s-appointment__group__title">{dossier.appointment.establishment.name}</h1>
                    }

                    {dossier.appointment.establishment &&
                    <p>{dossier.appointment.establishment.address.street} {dossier.appointment.establishment.address.house_number}, {dossier.appointment.establishment.address.zip_code} {dossier.appointment.establishment.address.city}</p>
                    }

                    {dossier.appointment.date_start &&
                    <p>Gekozen voorkeursdatum {format(new Date(dossier.appointment.date_start), 'EEEE d MMMM yyyy', {locale: nl})}, {dossier.appointment.start_time} - {dossier.appointment.end_time}</p>
                    }

                </div>

        )


    } else return null

}

export default OverviewAppointment
