import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import VehicleInformationState from "../../common/interfaces/VehicleInformationState";


const initialState: VehicleInformationState = {
    lease: {},
    fundingType: '',
    insurance: {},
    license: '',
    brand: '',
    damageDate: '',
    model: '',
    glassDamage: {},
    damageCause: {}
}

const vehicleInformationSlice = createSlice({
    name: 'vehicleInformation',
    initialState,
    reducers: {
        /** License **/
        setLicense: (state, action: PayloadAction<string>) => {
            state.license = action.payload
            state.brand = ''
            state.model = ''
        },
        /** Damage date **/
        setDamageDate: (state, action: PayloadAction<string>) => {
            state.damageDate = action.payload
        },
        /** Damage cause **/
        setDamageCause: (state, action: PayloadAction<number>) => {
            state.damageCause.selected = action.payload
        },
        unsetDamageCause: (state) => {
            delete state.damageCause.selected
        },
        /** Brand & Model **/
        setBrand: (state, action: PayloadAction<string>) => {
            state.brand = action.payload
        },
        setModel: (state, action: PayloadAction<string>) => {
            state.model = action.payload
        },
        setBuildYear: (state, action: PayloadAction<number>) => {
            state.buildYear = action.payload
        },
        setFundingType: (state, action: PayloadAction<string>) => {
            state.fundingType = action.payload
        },
        setLeaseActiveContractor: (state, action: PayloadAction<number>) => {
            state.lease.activeContractorId = action.payload
            delete state.lease.type
        },
        setLeaseType: (state, action: PayloadAction<string | boolean>) => {
            state.lease.type = action.payload
        },
        /** Insurance **/
        setInsuranceActiveContractor: (state, action: PayloadAction<number>) => {
            state.insurance.activeContractorId = action.payload
        },
        setGreenCardCode: (state, action: PayloadAction<string>) => {
            state.greenCardCode = action.payload
        },
        /** Glass damage **/
        unsetGlassDamage: (state) => {
            delete state.glassDamage.damageLocation
            delete state.glassDamage.damageSize
            delete state.glassDamage.damageExactLocation
            delete state.glassDamage.damageExactSize
            delete state.glassDamage.damageCause
        },
        setGlassDamageLocation: (state, action: PayloadAction<number>) => {
            state.glassDamage.damageLocation = action.payload
            delete state.glassDamage.damageSize
            delete state.glassDamage.damageExactLocation
            delete state.glassDamage.damageExactSize
            delete state.glassDamage.damageCause
        },
        setGlassDamageSize: (state, action: PayloadAction<number>) => {
            state.glassDamage.damageSize = action.payload
            delete state.glassDamage.damageExactLocation
            delete state.glassDamage.damageExactSize
            delete state.glassDamage.damageCause
        },
        setGlassDamageExactLocation: (state, action: PayloadAction<number>) => {
            state.glassDamage.damageExactLocation = action.payload
            delete state.glassDamage.damageExactSize
            delete state.glassDamage.damageCause
        },
        setGlassDamageExactSize: (state, action: PayloadAction<number>) => {
            state.glassDamage.damageExactSize = action.payload
            delete state.glassDamage.damageCause
        },
        setGlassDamageCause: (state, action: PayloadAction<number>) => {
            state.glassDamage.damageCause = action.payload
        }
    },
})

export const {
    setLicense,
    setBrand,
    setDamageDate,
    setDamageCause,
    unsetDamageCause,
    unsetGlassDamage,
    setModel,
    setBuildYear,
    setFundingType,
    setLeaseActiveContractor,
    setLeaseType,
    setInsuranceActiveContractor,
    setGreenCardCode,
    setGlassDamageCause,
    setGlassDamageExactSize,
    setGlassDamageExactLocation,
    setGlassDamageLocation,
    setGlassDamageSize
} = vehicleInformationSlice.actions
export const vehicleInformationReducer = vehicleInformationSlice.reducer


